import React from 'react';
import styled from 'styled-components';

import Feature from '../components/Feature/Feature';
import PriceCard from '../components/PriceCard/PriceCard';
import {Image360Icon, AddIcon, AnalyticsIcon, ChecklistIcon, DragDropIcon, MediaIcon, ScreenIcon, TeleportIcon, PrivateIcon, MultiplatformIcon } from '../assets/icons';

import HeroBackground from '../assets/img/pricing-hero-background.jpg';

const Hero = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2.4rem;
  background: url(${HeroBackground}) center / cover no-repeat;
  border-radius: var(--border-radius-surface) var(--border-radius-surface) 0 0;
`;

const HeroContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 60rem;
  width: 100%;
  max-width: var(--page-content-width);
`;

const PricingSection = styled.section`
  position: relative;
  margin-top: -2rem;
  padding: 12rem 2.4rem;
  background: var(--color-surface-light);
  border-radius: var(--border-radius-surface);
`;

const PricingPlans = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: var(--page-content-width);
  margin: 0 auto;
  > div {
    flex: 1;
    :not(:last-child) {
      margin-right: 2rem;
    }
  }
  /* @media only screen and (max-width: 1200px) {
    flex-wrap: wrap;
  }
  @media only screen and (max-width: 640px) {
    display: flex;
    flex-direction: column;
  } */
`;

const FeaturesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -2rem;
  padding: 14rem 2rem 0;
  border-left: 0.2rem solid #D8DFDD;
  border-right: 0.2rem solid #D8DFDD;
  div {
    margin-bottom: 6.4rem;
  }
`;

const PricingPage = () => {
  return (
    <>
      <Hero>
        <HeroContent>
          <h1>Pricing</h1>
          <p className='subtitle'>Donec duis volutpat sagittis viverra auctor</p>
        </HeroContent>
      </Hero>

      <PricingSection>
          <PricingPlans>
            <div>
              <PriceCard planName='Plan' price='39.99' features={['Lorem ipsum', 'Dolor sit amet', 'Duis lacinia', 'Sollicitudin ac sapien']} />
              <FeaturesWrapper>
                <Feature icon={DragDropIcon} title='Simple Drag & Drop'>Our intuitive interface enables you to create a virtual tour through a drag and drop system. No coding necessary!</Feature>
                <Feature icon={Image360Icon} title='Upload Your Own 360 Image'>Our platform supports any panoramic equirectangular JPG or PNG file.</Feature>
                <Feature icon={ScreenIcon} title='Home Screen'>Select a starting screen that best fits your experience: a map or an image.</Feature>
              </FeaturesWrapper>
            </div>

            <div>
              <PriceCard planName='Plan' price='39.99' features={['Lorem ipsum', 'Dolor sit amet', 'Duis lacinia', 'Sollicitudin ac sapien']} accentColor='#6EBDC8' />
              <FeaturesWrapper>
                <Feature icon={MediaIcon} title='Embed Audio & Video'>Through using our Information Points, enrich your 360 scenes with text information, audio, or video files to wow your consumers!</Feature>
                <Feature icon={ChecklistIcon} title='Questionnaire'>Evaluate the performance of your employees or conduct a customer survey by adding Question Points to your 360 scenes!</Feature>
                <Feature icon={AnalyticsIcon} title='Analytics'>Our easy-to-follow analytics enable you to monitor your consumers’ behavior in the virtual tour and gain invaluable insights into their interests and knowledge.</Feature>
              </FeaturesWrapper>
            </div>

            <div>
              <PriceCard planName='Plan' price='39.99' features={['Lorem ipsum', 'Dolor sit amet', 'Duis lacinia', 'Sollicitudin ac sapien']} accentColor='linear-gradient(180deg, #6EBDC8 0%, #6EC891 100%);' />
              <FeaturesWrapper>
                <Feature icon={TeleportIcon} title='Travel Directly from Scene to Scene'>Create a seamless experience where your consumers can travel from one 360 scene to another without needing to return to the main screen.</Feature>
                <Feature icon={PrivateIcon} title='Public or Private'>Publish your experience for anyone to access, or make it private and share it only with specific users.</Feature>
                <Feature icon={MultiplatformIcon} title='Virtual Tour for Any Device'>Our easy-to-follow analytics enable you to monitor your consumers’ behavior in the virtual tour and gain invaluable insights into their interests and knowledge.</Feature>
                <Feature icon={AddIcon} title='Add a New Feature'>Our experiences work on desktop, mobile devices, and VR headsets!</Feature>
              </FeaturesWrapper>
            </div>
          </PricingPlans>
      </PricingSection>
    </>
  )
}

export default PricingPage;
