import React from 'react';
import styled from 'styled-components';

import LinkButton from '../Buttons/LinkButton';

const StyledPriceCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4.8rem;
  font-weight: 700;
  font-size: 1.8rem;
  color: var(--color-body-dark);
  background: #ffffff;
  border-radius: var(--border-radius-surface);
  box-shadow: var(--shadow-surface);
  overflow: hidden;
  ::before {
      position: absolute;
      content: '';
      display: block;
      left: 0;
      top: 0;
      right: 0;
      height: 0.8rem;
      background: ${props => props.accentColor};
  }
`;

const Price = styled.div`
  font-size: 7.2rem;
  margin-bottom: 1.2rem;
  white-space: nowrap;
  span {
    display: inline-block;
    margin-top: 1.4rem;
    margin-right: 0.8rem;
    font-size: 2.4rem;
    vertical-align: top;
  }
  @media only screen and (max-width: 1200px) {
    font-size: 6.4rem;
  }
  @media only screen and (max-width: 1000px) {
    font-size: 5.6rem;
  }
`;

const FeaturesList = styled.ul`
  margin: 4.8rem 0;
  list-style: none;
  li:not(:last-child) {
    margin-bottom: 2.4rem;
  }
`;

const PriceCard = ({ planName='', price='', features=[], accentColor='#BDBDBD'}) => {
  return (
    <StyledPriceCard accentColor={accentColor}>
      <h3>{planName}</h3>
      <Price><span>$</span>{price}</Price>
      <div>per month</div>
      <FeaturesList>
          {features.map((feature, index) => <li key={index}>{feature}</li>)}
      </FeaturesList>
      <LinkButton type='internal' href='/contact-us' backgroundColor={accentColor} textColor='var(--color-body-dark)' >Contact us</LinkButton>
    </StyledPriceCard>
  )
}

export default PriceCard;
